exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-article-list-tsx": () => import("./../../../src/templates/ArticleList.tsx" /* webpackChunkName: "component---src-templates-article-list-tsx" */),
  "component---src-templates-article-tsx-content-file-path-blog-前端-d-3-with-react-md": () => import("./../../../src/templates/Article.tsx?__contentFilePath=/Users/xiangda/projects/arm/xd-blog/blog/前端/d3-with-react.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-blog-前端-d-3-with-react-md" */),
  "component---src-templates-article-tsx-content-file-path-blog-前端-indexeddb-md": () => import("./../../../src/templates/Article.tsx?__contentFilePath=/Users/xiangda/projects/arm/xd-blog/blog/前端/indexeddb.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-blog-前端-indexeddb-md" */),
  "component---src-templates-article-tsx-content-file-path-blog-前端-p-react-signals-md": () => import("./../../../src/templates/Article.tsx?__contentFilePath=/Users/xiangda/projects/arm/xd-blog/blog/前端/PReact-Signals.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-blog-前端-p-react-signals-md" */),
  "component---src-templates-article-tsx-content-file-path-blog-前端-twaildcss-md": () => import("./../../../src/templates/Article.tsx?__contentFilePath=/Users/xiangda/projects/arm/xd-blog/blog/前端/Twaildcss.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-blog-前端-twaildcss-md" */),
  "component---src-templates-article-tsx-content-file-path-blog-后端-iteaable-md": () => import("./../../../src/templates/Article.tsx?__contentFilePath=/Users/xiangda/projects/arm/xd-blog/blog/后端/Iteaable.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-blog-后端-iteaable-md" */),
  "component---src-templates-article-tsx-content-file-path-blog-后端-node-test-and-tsest-md": () => import("./../../../src/templates/Article.tsx?__contentFilePath=/Users/xiangda/projects/arm/xd-blog/blog/后端/Node Test and tsest.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-blog-后端-node-test-and-tsest-md" */),
  "component---src-templates-article-tsx-content-file-path-blog-工具党-buy-pc-for-work-mdx": () => import("./../../../src/templates/Article.tsx?__contentFilePath=/Users/xiangda/projects/arm/xd-blog/blog/工具党/buy-pc-for-work.mdx" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-blog-工具党-buy-pc-for-work-mdx" */),
  "component---src-templates-article-tsx-content-file-path-blog-工具党-note-software-mdx": () => import("./../../../src/templates/Article.tsx?__contentFilePath=/Users/xiangda/projects/arm/xd-blog/blog/工具党/NoteSoftware.mdx" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-blog-工具党-note-software-mdx" */),
  "component---src-templates-article-tsx-content-file-path-blog-思考-bad-smell-code-mdx": () => import("./../../../src/templates/Article.tsx?__contentFilePath=/Users/xiangda/projects/arm/xd-blog/blog/思考/bad-smell-code.mdx" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-blog-思考-bad-smell-code-mdx" */),
  "component---src-templates-article-tsx-content-file-path-blog-思考-记刘润年度演讲翻车事件-md": () => import("./../../../src/templates/Article.tsx?__contentFilePath=/Users/xiangda/projects/arm/xd-blog/blog/思考/记刘润年度演讲翻车事件.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-blog-思考-记刘润年度演讲翻车事件-md" */),
  "component---src-templates-article-tsx-content-file-path-blog-杂记-技术产业的相关思考-the-10-x-programmer-mdx": () => import("./../../../src/templates/Article.tsx?__contentFilePath=/Users/xiangda/projects/arm/xd-blog/blog/杂记/技术产业的相关思考/The 10x Programmer.mdx" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-blog-杂记-技术产业的相关思考-the-10-x-programmer-mdx" */),
  "component---src-templates-article-tsx-content-file-path-blog-杂记-生活中的变化与思考-博客工具历史小记-mdx": () => import("./../../../src/templates/Article.tsx?__contentFilePath=/Users/xiangda/projects/arm/xd-blog/blog/杂记/生活中的变化与思考/博客工具历史小记.mdx" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-blog-杂记-生活中的变化与思考-博客工具历史小记-mdx" */),
  "component---src-templates-article-tsx-content-file-path-blog-杂记-生活中的变化与思考-博客，再出发，立-flag-mdx": () => import("./../../../src/templates/Article.tsx?__contentFilePath=/Users/xiangda/projects/arm/xd-blog/blog/杂记/生活中的变化与思考/博客，再出发，立 Flag.mdx" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-blog-杂记-生活中的变化与思考-博客，再出发，立-flag-mdx" */),
  "component---src-templates-article-tsx-content-file-path-blog-杂记-生活中的变化与思考-四线小城的his-mdx": () => import("./../../../src/templates/Article.tsx?__contentFilePath=/Users/xiangda/projects/arm/xd-blog/blog/杂记/生活中的变化与思考/四线小城的HIS.mdx" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-blog-杂记-生活中的变化与思考-四线小城的his-mdx" */)
}

